.popup-mobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 7;
  display: flex;
  align-items: center;
  justify-content: center; }
  .popup-mobile .popup-mobile-blur {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0; }
    .popup-mobile .popup-mobile-blur_1 {
      background-color: white; }
    .popup-mobile .popup-mobile-blur_2 {
      background: linear-gradient(180deg, #99FFEE 23.56%, #04D6FF 100%); }
  .popup-mobile .popup-mobile-body {
    position: relative;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: start;
    justify-content: center; }
  .popup-mobile .popup-mobile-img {
    scale: 1.2;
    top: 70px;
    position: absolute; }
  .popup-mobile__btn-close {
    position: absolute;
    z-index: 10;
    left: calc(100vw - 40px);
    top: 10px;
    background-image: url("../images/icons/i-popup-close-black.svg");
    background-color: transparent;
    border: none;
    width: 24px;
    height: 24px;
    cursor: pointer; }
  .popup-mobile__btn-download {
    position: absolute;
    left: calc(50vw - 105px);
    bottom: 40px;
    z-index: 10;
    width: 210px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    border: none;
    cursor: pointer;
    transition: .3s;
    color: white;
    font-size: 30px;
    font-weight: 700;
    text-align: center; }
    .popup-mobile__btn-download_1 {
      background: #ff0055; }
      .popup-mobile__btn-download_1:hover {
        background: #e4004b; }
    .popup-mobile__btn-download_2 {
      background: #ff6a00; }
      .popup-mobile__btn-download_2:hover {
        background: #e66000; }
